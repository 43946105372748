import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "react-error-boundary";


// Importing auth services
import { AuthProvider } from "./provider/AuthContext";
import { QueryProvider } from "./provider/QueryProvider";

// Importing page layout
import Layout from "./layout";
import { NetworkProvider } from "./provider/NetworkContext";
import { DisableInspect } from "./DisableInspect";

// lazy loading
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

function App() {
 
  return (
    <NetworkProvider>
    <QueryProvider>
      <AuthProvider>
        <Suspense fallback={"Loading..."}>
          <ErrorBoundary FallbackComponent={NotFoundPage}>
            <Router>
              <DisableInspect />
              <Layout/>
              <Toaster
                position="top-right"
                toastOptions={{
                  style: {
                    color: "#fff",
                    background: "#101010",
                  },
                }}
              />
            </Router>
          </ErrorBoundary>
        </Suspense>
      </AuthProvider>
    </QueryProvider>
    </NetworkProvider>
  );
}

export default App;
