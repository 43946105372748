import React, { useEffect } from 'react';

export const DisableInspect = () => {
  useEffect(() => {
    // Prevent right-click context menu
    const handleContextMenu = (e:any) => {
      e.preventDefault();
    };

    // Prevent keyboard shortcuts for developer tools
    const handleKeyDown = (e:any) => {
      // Prevent F12, Ctrl+Shift+I, Ctrl+U, Cmd+Option+I
      if (
        e.key === 'F12' || 
        (e.ctrlKey && e.shiftKey && e.key === 'I') || 
        (e.ctrlKey && e.key === 'u') ||
        (e.metaKey && e.altKey && e.key === 'i')
      ) {
        e.preventDefault();
        return false;
      }
    };

    // Detect opened DevTools
    const checkDevTools = () => {
      const devToolsOpen = 
        window.outerWidth - window.innerWidth > 100 || 
        window.outerHeight - window.innerHeight > 100;
      
      if (devToolsOpen) {
        window.close();
      }
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    
    // Check for DevTools periodically
    const devToolsInterval = setInterval(checkDevTools, 1000);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
      clearInterval(devToolsInterval);
    };
  }, []);

  return null;
};